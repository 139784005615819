
$(document).ready(function () {
    /* Global */
    // Checkbox
    let checkbox = $('.icheck');

    if (checkbox.length > 0) {
        $('input').iCheck({
            checkboxClass: 'icheckbox_flat-orange',
            radioClass: 'iradio_flat-orange',
        });
    }

    /* Date Input Mask */
    let dateInputsMask = document.querySelectorAll('.date-input-mask');

    Array.from(dateInputsMask, (input) => {
        input.addEventListener('keypress', (e) => {
            if (e.keyCode < 47  || e.keyCode > 57) {
                e.preventDefault();
            }

            let len = input.value.length;
            
            if (len === 4 || len === 7) {
                input.value += "-";
            }

            if (e.keyCode === 47 || len === 10) {
                e.preventDefault();
            }
        })
    })
    /* /Date Input Mask */

    /* /Global */

    /* Sidebar Script */
    let sidebarToggler = $('.sidebar-toggle');
    let sidebar = $('#sidebar');
    let closeSidebar = $('.close-sidebar');
    let mainContent = $('.main');

    if (window.innerWidth < 992) {
        sidebar.addClass('closed');
        sidebar.removeClass('active');
    }

    sidebarToggler.on('click', function () {
        if (sidebar.hasClass('closed')) {
            sidebar.addClass('active');
        } else {
            sidebar.removeClass('active');
        }
        sidebar.toggleClass('closed');

        // Remove margin if sidebar collapsed
        if (sidebar.hasClass('active') == false) {
            mainContent.addClass('sidebar-collapsed');
        } else {
            mainContent.removeClass('sidebar-collapsed');
        }
    });

    closeSidebar.on('click', function () {
        sidebar.removeClass('active');
        sidebar.addClass('closed');
    });

    // Remove margin if sidebar collapsed
    if (sidebar.hasClass('active') == false) {
        mainContent.addClass('sidebar-collapsed');
    }
    /* /Sidebar Script */

    /* Preview Uploaded Image Script for Slideshow and new.slideshow */
    function readURL(input, img) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                img.attr('src', e.target.result);
            }

            reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
    }

    $(".img-upload").change(function () {
        readURL(this, $(this).parent().next().find('img'));
    });
    /* /Preview Uploaded Image Script for Slideshow and new.slideshow */

    /* Users Table */
    // Detect status content and change color relatively
    // Refresh color was moved to shared.js
    let usersTable = $('#users-table');

    if (usersTable.length) {
        let theUsersTable = usersTable.DataTable({
            "fnDrawCallback": function () {
                refreshColors();
            },

            language: {
                search: $searchTrans,
                lengthMenu: `${$showTrans} _MENU_ ${$entriesTrans}`,
                info: `${$showingTrans} _START_ ${$toTrans} _END_ ${$ofTrans} _TOTAL_ ${$entriesTrans}`,

                paginate: {
                    previous: `${$prevTrans}`,
                    next: `${$nextTrans}`,
                },
            }
        });
    }
    /* /Users Table */

    /* === [ Start Services ] === */
    /* Services Table */
    let servicesTable = $('#services-table');

    if (servicesTable.length) {
        servicesTable.DataTable({
            "fnDrawCallback": function () {
                refreshColors();
            },

            "columnDefs": [
                { "width": "20px", "targets": 0 }
            ],

            language: {
                search: $searchTrans,
                lengthMenu: `${$showTrans} _MENU_ ${$entriesTrans}`,
                info: `${$showingTrans} _START_ ${$toTrans} _END_ ${$ofTrans} _TOTAL_ ${$entriesTrans}`,

                paginate: {
                    previous: `${$prevTrans}`,
                    next: `${$nextTrans}`,
                },
            }
        });
    }
    /* /Services Table */

    /* Active Service */
    $(".upload-service-icon").change(function () {
        readURL(this, $(this).parent().find('img'));
    });
    /* /Active Service */
    /* === [ End Services ] === */

    /* === [ Start View Locations ] === */
    // Start Locations Table
    let locationsTable = $('#locations-table');
    if (locationsTable.length) {
        locationsTable.DataTable({
            "columnDefs": [
                { "width": "20px", "targets": 0 }
            ],

            language: {
                search: $searchTrans,
                lengthMenu: `${$showTrans} _MENU_ ${$entriesTrans}`,
                info: `${$showingTrans} _START_ ${$toTrans} _END_ ${$ofTrans} _TOTAL_ ${$entriesTrans}`,

                paginate: {
                    previous: `${$prevTrans}`,
                    next: `${$nextTrans}`,
                },
            }
        });
    }
    // End Locations Table
    /* === [ End View Locations ] === */

    // Confirm before delete and change password
    let deleteBtn = $('.delete-btn');
    let changePassBtn = $('.changepass-btn');
    let resetPassBtn = $('.resetpass-btn');

    deleteBtn.on('click', function () {
        let confirmMsg;
        if (pageLang == 'ar') {
            confirmMsg = "هل أنت متأكد من إكمال عملية الحذف؟";
        } else {
            confirmMsg = "Are you sure you wanna remove the selected item?"
        }
        if (confirm(confirmMsg)) {
            return
        } else {
            return false;
        }
    });

    changePassBtn.on('click', function () {
        let confirmMsg;
        if (pageLang == 'ar') {
            confirmMsg = "هل متأكد من أنك تريد تغيير الرمز السري؟";
        } else {
            confirmMsg = "Are you sure that you want to change the password?"
        }
        if (confirm(confirmMsg)) {
            return
        } else {
            return false;
        }
    });

    resetPassBtn.on('click', function () {
        let confirmMsg;
        if (pageLang == 'ar') {
            confirmMsg = "هل أنت متأكد بأنك تريد إعادة تعيين كلمة المرور؟";
        } else {
            confirmMsg = "Are you sure that you want to reset the password?"
        }
        if (confirm(confirmMsg)) {
            return
        } else {
            return false;
        }
    });

    // =========================================== //

    // === [ Start Inputs Validations ] === //
    // Init Trans Variables
    let onlyNumbersMsg;
    let emailInvalidMsg;
    let siteInvalid;
    let httpSiteInvalid;

    if (pageLang == 'ar') {
        onlyNumbersMsg = "يمكنك إدخال أرقام فقط";
        emailInvalidMsg = "الرجاء إدخال بريد الكتروني صالح"
        siteInvalid = "صيغة العنوان الإلكتروني غير صحيحة"
        httpSiteInvalid = "يجب أن يبدأ العوان ب Http أو https://www.xxxxxxxx";
        usernameInvalid = "يمكنك فقط استعمال الأحرف الإنجليزية, الأرقام والرموز"

    } else {
        onlyNumbersMsg = "Only Numbers Are Allowed...!";
        emailInvalidMsg = "Please enter a valid email address";
        siteInvalid = "Site is invalid";
        httpSiteInvalid = "Website URL should starts with http/https.www.xxxxxxxx";
        usernameInvalid = "You can only use English Letters, Numbers and symbols";
    }

    // Validations Rules
    let emailReg = /^$|([\w\-\.]{2,}\@[a-zA-Z-0-9]{2,}\.[\w\-]{2,4})/;
    let siteReg = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    let httpSiteReg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    let usernameReg = /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/;

    // Checking Number Validations
    let numberInputs = document.querySelectorAll(".number-input");

    for (i = 0; i < numberInputs.length; i++) {
        numberInputs[i].addEventListener("keypress", function (event) {
            if (event.which < 48 || event.which > 57) {
                validNumber = false;
                toastr.warning(onlyNumbersMsg);
                event.preventDefault();
            }
        });

        numberInputs[i].addEventListener('paste', function (event) {
            event.preventDefault();
        });
    }

    let emailInputs = document.querySelectorAll(".email-input");
    let siteInputs = document.querySelectorAll(".site-input");
    let httpSiteInputs = document.querySelectorAll(".http-site-input");
    let usernameInputs = document.querySelectorAll(".username-input");

    // Track Validations Variables
    let validEmail = true;
    let validNumber = true;
    let validSite = true;
    let validHttpSite = true;
    let validUsername = true;

    // Checking Send Email Validations
    for (e = 0; e < emailInputs.length; e++) {
        emailInputs[e].addEventListener("change", function (event) {

            if (!emailReg.test(this.value) == true) {
                validEmail = false;
                toastr.warning(emailInvalidMsg);
                event.preventDefault();
            } else {
                validEmail = true;
            }
        });
    }

    // Checking Site Validations
    for (s = 0; s < siteInputs.length; s++) {
        siteInputs[s].addEventListener("change", function (event) {

            if (!siteReg.test(this.value) == true) {
                validSite = false;
                toastr.warning(siteInvalid);
                event.preventDefault();
            } else {
                validSite = true;
            }
        });
    }

    // Checking HTTP or HTTPS Site Validations
    for (h = 0; h < httpSiteInputs.length; h++) {
        httpSiteInputs[h].addEventListener("change", function (event) {

            if (!httpSiteReg.test(this.value) == true) {
                validHttpSite = false;
                toastr.warning(httpSiteInvalid);
                event.preventDefault();
            } else {
                validHttpSite = true;
            }
        });
    }

    // Checking Username Validations
    // for (u = 0; u < usernameInputs.length; u++) {
    //     usernameInputs[u].addEventListener("change", function (event) {

    //         if (!usernameReg.test(this.value) == true) {
    //             validUsername = false;
    //             toastr.warning(usernameInvalid);
    //             event.preventDefault();
    //         } else {
    //             validUsername = true;
    //         }
    //     });
    // }

    // Check all validations on save button click
    let contactSaveBtn = $('.validation-btn');

    contactSaveBtn.on('click', function (e) {
        if (validEmail == false) {
            toastr.warning(emailInvalidMsg);
            e.preventDefault();
        }
        else if (validSite == false) {
            toastr.warning(siteInvalid);
            e.preventDefault();
        }
        else if (validHttpSite == false) {
            toastr.warning(httpSiteInvalid);
            e.preventDefault();
        }
        else if (validUsername == false) {
            toastr.warning(usernameInvalid);
            e.preventDefault();
        }
    });
    // === [ End Inputs Validations ] === //

    // ============ START NEW SCRIPTS ================= //
    /* Claims Table */
    // Translations
    // let $searchTrans = "Search";
    // let $showTrans = "Show";
    // let $showingTrans = "Showing";
    // let $entriesTrans = "entries";
    // let $toTrans = "to";
    // let $ofTrans = "of";
    // let $prevTrans = "Previous";
    // let $nextTrans = "Next";

    // if (pageLang == 'ar') {
    //     $searchTrans = "ابحث";
    //     $showTrans = "مشاهدة";
    //     $showingTrans = "إظهار";
    //     $entriesTrans = "سجل";
    //     $toTrans = "إلى";
    //     $ofTrans = "من أصل";
    //     $prevTrans = "السابق";
    //     $nextTrans = "التالي";
    // }

    // Detect status content and change color relatively
    // let recordStatus = $('.table tbody tr td:last-of-type');

    // let refreshColors = () => {
    //     let activeStat = $('.table tbody tr td:last-of-type:contains(active)');
    //     let pendingStat = $('.table tbody tr td:last-of-type:contains(pending)');
    //     let disableStat = $('.table tbody tr td:last-of-type:contains(inactive)');

    //     if (pageLang == 'ar') {
    //         activeStat = $('.table tbody tr td:last-of-type:contains(نشط)');
    //         pendingStat = $('.table tbody tr td:last-of-type:contains(معلق)');
    //         disableStat = $('.table tbody tr td:last-of-type:contains(معطل)');
    //     }

    //     activeStat.css('color', '#4eb34e');
    //     pendingStat.css('color', 'orange');
    //     disableStat.css('color', 'red');
    // }
    // refreshColors();

    // recordStatus.on("DOMSubtreeModified", refreshColors);

    let monitoringTable = $('#monitoring-table');

    if (monitoringTable.length) {
        let theMonitoringTable = monitoringTable.DataTable({
            "fnDrawCallback": function () {
                refreshColors();
            },

            "initComplete": function () {
                datatableDropdownFilters();
            },

            language: {
                search: $searchTrans,
                lengthMenu: `${$showTrans} _MENU_ ${$entriesTrans}`,
                info: `${$showingTrans} _START_ ${$toTrans} _END_ ${$ofTrans} _TOTAL_ ${$entriesTrans}`,

                paginate: {
                    previous: `${$prevTrans}`,
                    next: `${$nextTrans}`,
                },
            }
        });

        function datatableDropdownFilters() {
            $('#claim-client-name').on('change', function () {
                theMonitoringTable.columns(1).search(this.value).draw();
            });

            $('#claim-status').on('change', function () {
                theMonitoringTable.columns(7).search(this.value).draw();
            });

            $('#city-filter').on('change', function () {
                theMonitoringTable.columns(8).search(this.value).draw();
            });
        }
    }
    /* /Claims Table */

    /* Main Dashboard Table */
    let dashboardTable = $('#dashboard-monitoring-table');

    if (dashboardTable.length) {
        let theDashboardTable = dashboardTable.DataTable({

            "fnDrawCallback": function () {
                refreshColors();
            },

            "initComplete": function () {
                datatableDropdownFilters();
            },

            // "initComplete": function () {
            //     datatableDropdownFilters();
            // },
        });

        // Define Export Buttons and change thier position
        let printTxt = 'Print';

        if (document.documentElement.lang === 'ar') {
            printTxt = 'طباعة';
        }

        new $.fn.dataTable.Buttons(theDashboardTable, {
            buttons: [
                {
                    extend: 'csv',
                    charset: 'utf-8',
                    extension: '.csv',
                    text: '<i class="fas fa-file-excel"></i> CSV',
                    titleAttr: 'CSV',
                    className: 'btn btn-default btn-sm',
                    bom: true,
                },

                {
                    extend: 'print',
                    text: `<i class="fas fa-print"></i> ${printTxt}`,
                    titleAttr: 'Print',
                    className: 'btn btn-default btn-sm',
                    bom: true,
                    charset: 'utf-8',
                    extension: '.pdf',
                }
            ]
        });


        theDashboardTable.buttons().container().appendTo($('#export-btns-container'));

        function datatableDropdownFilters() {
            $('#claim-status').on('change', function () {
                if (dashboardTable.hasClass("client-table")) {
                    theDashboardTable.columns(6).search(this.value).draw();
                    return;
                }
                theDashboardTable.columns(4).search(this.value).draw();
            });

            $("#claim-date").on('keyup', function (e) {
                if (dashboardTable.hasClass("client-table")) {
                    theDashboardTable.columns(1).search(this.value).draw();
                    return;
                }
                theDashboardTable.columns(2).search(this.value).draw();
            });
        }
    }
    /* /Main Dashboard Table */

    /* ===[Start Replace the pdf button came from MAKE PDF library to be inside the buttons generated by Datatable library]=== */
    let makePdfBtn = document.querySelector('button[name=pdf]');
    let requiredPosition;

    setTimeout(() => {
        requiredPosition = document.querySelector('.dt-buttons');
        replaceMakePdfBtnPosition();
    }, 200);
    

    function replaceMakePdfBtnPosition() {        
        if (makePdfBtn !== null && requiredPosition !== null) {
            let clonedMakePdfBtn = makePdfBtn.cloneNode(true);
            clonedMakePdfBtn.classList.add('cloned-pdf-btn');
            makePdfBtn.remove();
            requiredPosition.appendChild(clonedMakePdfBtn);
        }
    }
    /* ===[End Replace the pdf button came from MAKE PDF library to be inside the buttons generated by Datatable library]=== */
});

// Toastr
toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}